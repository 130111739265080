/* 
export const vkey = {
  protocol: "groth16",
  curve: "bn128",
  nPublic: 21,
  vk_alpha_1: [
    "20491192805390485299153009773594534940189261866228447918068658471970481763042",
    "9383485363053290200918347156157836566562967994039712273449902621266178545958",
    "1",
  ],
  vk_beta_2: [
    ["6375614351688725206403948262868962793625744043794305715222011528459656738731", "4252822878758300859123897981450591353533073413197771768651442665752259397132"],
    ["10505242626370262277552901082094356697409835680220590971873171140371331206856", "21847035105528745403288232691147584728191162732299865338377159692350059136679"],
    ["1", "0"],
  ],
  vk_gamma_2: [
    ["10857046999023057135944570762232829481370756359578518086990519993285655852781", "11559732032986387107991004021392285783925812861821192530917403151452391805634"],
    ["8495653923123431417604973247489272438418190587263600148770280649306958101930", "4082367875863433681332203403145435568316851327593401208105741076214120093531"],
    ["1", "0"],
  ],
  vk_delta_2: [
    ["20536448868052571171670766235318232115848913256594482835722646387022981948632", "6000163699235006893443306275332042954252661854661784159693946044989377098783"],
    ["4516015772111037944187264821240366560810172943735882088874881139630185981871", "19144212726952098782633973455530756687939147201049764436425855017202088293155"],
    ["1", "0"],
  ],
  vk_alphabeta_12: [
    [
      ["2029413683389138792403550203267699914886160938906632433982220835551125967885", "21072700047562757817161031222997517981543347628379360635925549008442030252106"],
      ["5940354580057074848093997050200682056184807770593307860589430076672439820312", "12156638873931618554171829126792193045421052652279363021382169897324752428276"],
      ["7898200236362823042373859371574133993780991612861777490112507062703164551277", "7074218545237549455313236346927434013100842096812539264420499035217050630853"],
    ],
    [
      ["7077479683546002997211712695946002074877511277312570035766170199895071832130", "10093483419865920389913245021038182291233451549023025229112148274109565435465"],
      ["4595479056700221319381530156280926371456704509942304414423590385166031118820", "19831328484489333784475432780421641293929726139240675179672856274388269393268"],
      ["11934129596455521040620786944827826205713621633706285934057045369193958244500", "8037395052364110730298837004334506829870972346962140206007064471173334027475"],
    ],
  ],
  IC: [
    ["12994226918873589184973686928509303296560829040421679966554788871610722975666", "19546003293245789033051514407489605917742869051853168678675952551588673264059", "1"],
    ["12803481875393106746548008498445134277525885425145232429904868659552497497795", "14694537564331845125620823836332201159223992527131190273671921404972056438350", "1"],
    ["12896526600976836570191398155392714054965141616837004359997046349335306162721", "19989434935534803930041038636337516057507187085720073704180685028185491943059", "1"],
    ["1316318882292921074427417498918167024758763552548146498412670277806150908690", "4496675464734154714119497950957313125247358066733690814270807794291803734246", "1"],
    ["6095412520421880843686410733562452820125095067123376214642702693632219147919", "18805124981571223044033930919909367820198635605339887372165893879595885917681", "1"],
    ["2491592648113503772389081945290063076975598019801589629533440501442845841156", "13391234389463383825331371383043116227852107840306198632599020502591729622484", "1"],
    ["6195983279938368142523765739331129953873741365958978404100950251126336420619", "15255523481575331608052032202923784309897551868856977174561927351542766503103", "1"],
    ["11364908849898553727983936508468303936453241846181145049706378426559053436737", "12272143028059829557388953943471298784072389097212881142482116493849228345944", "1"],
    ["7999645601849353569331229788079982131126962928370124718709121582700741706743", "2344057992213881398120960053462701488822252700578864159913460281949725551948", "1"],
    ["16032444542449207733074464012443666729463280555253921300321226108727384337838", "15550284302068130534143630938965558367339743479932980144836777854141162045599", "1"],
    ["14746811931688438261460396174433278363575571145186986664727520211845005639214", "12430585284621277256717932006846197725061776365493376477691372628343500830841", "1"],
    ["9665472323900251212386389887072355571286254407686859980386007760322469415859", "14996755965687753932946931756264151392152419779360225521200890974252292420412", "1"],
    ["108004501448464767377877268616442057348817885652988877682944326307051832181", "14352072319589824562053349181969574390668378410837840879062264124706247340441", "1"],
    ["8241644122136047663973273182299008352335433437918941847881738731207527978408", "13326634974373964259912482627512460794400032234388182531625125624431239351434", "1"],
    ["19079198509719557568724303856873450227277338931054655042976782137976461081455", "20099741154760778542873760951130722650539994221026926650223788312866275905057", "1"],
    ["12776213961119493875955017544545281842733223900358858158966892223397818263003", "13049175944477161115063479837952228706325305696565228051297866932929773226361", "1"],
    ["3677175527878880765823827286256329959476800348470829607774669755005589328592", "5960302772589853676080934160184945132281885414289323557538680756680173217763", "1"],
    ["3845518388361833739355416292477732378976881884807825706781410508823338000844", "14643485171032551307429410040201611507475314617619869031588843852404758800222", "1"],
    ["12781800159969915448548725085816037348380685059680464559080293032138144200069", "8293835978235721621083584748448494414574099111793175131815478014921624321923", "1"],
    ["2679784852932993701185917737851208220646508350009220493871893673616712782876", "2455480317203964036392487392172013165313334147345673527798783221956163967174", "1"],
    ["4240406231108001399618612059193031408047888754255460798137984403589376061488", "9804154717486719329041740755091695338827486645577286903421673068934216499944", "1"],
    ["12028012375636649231431542422094056328652150783328682821491835725444465403330", "330627474571770559729829431278328850519463731324979004581250682428211832702", "1"],
  ],
};
*/

export const vkey = {
  "protocol": "groth16",
  "curve": "bn128",
  "nPublic": 40,
  "vk_alpha_1": [
   "20491192805390485299153009773594534940189261866228447918068658471970481763042",
   "9383485363053290200918347156157836566562967994039712273449902621266178545958",
   "1"
  ],
  "vk_beta_2": [
   [
    "6375614351688725206403948262868962793625744043794305715222011528459656738731",
    "4252822878758300859123897981450591353533073413197771768651442665752259397132"
   ],
   [
    "10505242626370262277552901082094356697409835680220590971873171140371331206856",
    "21847035105528745403288232691147584728191162732299865338377159692350059136679"
   ],
   [
    "1",
    "0"
   ]
  ],
  "vk_gamma_2": [
   [
    "10857046999023057135944570762232829481370756359578518086990519993285655852781",
    "11559732032986387107991004021392285783925812861821192530917403151452391805634"
   ],
   [
    "8495653923123431417604973247489272438418190587263600148770280649306958101930",
    "4082367875863433681332203403145435568316851327593401208105741076214120093531"
   ],
   [
    "1",
    "0"
   ]
  ],
  "vk_delta_2": [
   [
    "9807164380251633320270781057851447975413763120899682835651746536114812553994",
    "10343081133609258106524901662136446830203953069418302653607138025109354852056"
   ],
   [
    "19577108980448557774164310518706121321497510178047308624728190351127007029613",
    "9944420113684168402007836039557494668185622235286586265128723919315954929511"
   ],
   [
    "1",
    "0"
   ]
  ],
  "vk_alphabeta_12": [
   [
    [
     "2029413683389138792403550203267699914886160938906632433982220835551125967885",
     "21072700047562757817161031222997517981543347628379360635925549008442030252106"
    ],
    [
     "5940354580057074848093997050200682056184807770593307860589430076672439820312",
     "12156638873931618554171829126792193045421052652279363021382169897324752428276"
    ],
    [
     "7898200236362823042373859371574133993780991612861777490112507062703164551277",
     "7074218545237549455313236346927434013100842096812539264420499035217050630853"
    ]
   ],
   [
    [
     "7077479683546002997211712695946002074877511277312570035766170199895071832130",
     "10093483419865920389913245021038182291233451549023025229112148274109565435465"
    ],
    [
     "4595479056700221319381530156280926371456704509942304414423590385166031118820",
     "19831328484489333784475432780421641293929726139240675179672856274388269393268"
    ],
    [
     "11934129596455521040620786944827826205713621633706285934057045369193958244500",
     "8037395052364110730298837004334506829870972346962140206007064471173334027475"
    ]
   ]
  ],
  "IC": [
   [
    "13911334274401924343675544560041308512588936901424910915603782906803297287632",
    "2706123920292725976458900370590743282893519207291145650034358362573351809778",
    "1"
   ],
   [
    "6985983538948125859102879759305585957033692335816034727504459126154150675393",
    "19972750462935050417295831096846277473290254620709512808517185507733918100846",
    "1"
   ],
   [
    "7744013186186492394192214327299469757175689346110767167154898216471196095085",
    "6702729055720766626065307937755216430432678137682221614353038778589657960048",
    "1"
   ],
   [
    "255915235030371991993484001712616086436758891388484439648352245043294529900",
    "4179505299213548642445718315004146505466351834266056645355508781336008368451",
    "1"
   ],
   [
    "14665449237617429987947939493398796988508835581367002665682766941024976091705",
    "762004656482205873626930109934934985880770906990530286373664203254704399119",
    "1"
   ],
   [
    "9891081518337938052858589684877032246112975663226300403315954293261278376940",
    "19489996687572280863382620397533026772053282893687720955820232087604849495176",
    "1"
   ],
   [
    "5800952410174350989374575600427733477116335318735807160590138906298167392978",
    "10087684366978175789571270859672147139504313886600685836467201147453228792716",
    "1"
   ],
   [
    "21351332895842959440410596862055681606274721309941311913188097986540391756486",
    "2307072863391971707807462264997264310991255504705487943650727153057932486703",
    "1"
   ],
   [
    "13010659854056812016439519531663311954437532545284071926934993582761474224894",
    "15816218888092633942089854415791255109502438402393571397446345369212440492159",
    "1"
   ],
   [
    "5053705414025665707100333667090818209330898042069933520812706581768614416747",
    "15102167500006968672268298512544770652224200188765016527963864802106967926258",
    "1"
   ],
   [
    "18842434238151770363997463153238823211722305234308000152572121185461721165534",
    "2593387840584284469427225866602223950231412681510330394144072762948160157256",
    "1"
   ],
   [
    "15599182068312680660960536864833796975142938275261895467487695736451312294383",
    "4666518800946599840711720480266222815125027594955011646010035289619688501056",
    "1"
   ],
   [
    "9113330458144966221935075056421416883691137461488614310024809145998323557603",
    "14525998074188030501201355527081933288769508591085401978245999429699317995155",
    "1"
   ],
   [
    "1698361326922985986278517202575612983085060321585257306576729960115662047082",
    "10811440926949560531025358441147529667704238851901100869553842063328440863767",
    "1"
   ],
   [
    "21813322714619416501160428454453290155766777717262000799102654107816607855965",
    "1900098864322431724982182925647723282402120214677411997484653888105945993420",
    "1"
   ],
   [
    "4850391572444221222891473530479010507625931117274574478058276734887281510448",
    "21209333872097499618431030650092175877302366840044306035116464128115890800796",
    "1"
   ],
   [
    "11024325828225418385172578260153741127557638280432460894781093623688133027317",
    "12829771981770099593305823120912530429245566056736702770500768601904968866660",
    "1"
   ],
   [
    "6499199902234026878468170995556481877772698039370309856572671196533366057357",
    "4457036475648163147639419384098488748765647820786558552307664597920606634328",
    "1"
   ],
   [
    "18022767910853449640857081466776039368894005536449264312356527521962753150045",
    "19255367004765661489282403633399213738840373861881573851712640969865112253205",
    "1"
   ],
   [
    "3407915644635962426547873514159178484708180897582674981651195673063889056527",
    "20900842108041417167531653748937888806375160842841645236594766769740801086240",
    "1"
   ],
   [
    "20017654297737842052107095428206289877193449936297035323430379821054305801225",
    "18398515026520352890282481306377861550205376583366632991373903923950653555070",
    "1"
   ],
   [
    "21047973865590898317558361621568151146087679718605108018529528496898961038772",
    "21278634433260452963308259175825820317340782823939379578046404446402428234210",
    "1"
   ],
   [
    "8241145138357410146565515812882570766759410582275691347723108605200802022956",
    "1989081011088741796912864754739172167152133683074048511937493943117875172858",
    "1"
   ],
   [
    "17569097387405075516786396328891553530486899788694929338454645324083714108418",
    "2671272267873010509558066732587132159825241218632027523162826309557010040801",
    "1"
   ],
   [
    "18432934328198141990735900067700545183644918237318871758528883499458953191751",
    "12907904986298391408698324332133566949685445388692800711291464791587658944848",
    "1"
   ],
   [
    "11647174916828858228600836692025264965425510498861972640414508608610165498786",
    "2789266155005779176091573091508902987969786671406288086616050740944435032366",
    "1"
   ],
   [
    "7716599352357689662437791281378834953674147767709763441696690660416798000193",
    "2011956446519231175256986068510459528070989280957991078572908702061967826029",
    "1"
   ],
   [
    "14881679753221971431657980926845949992839985126401023404107224715610776731068",
    "9378592721036535509644483460953612255671078585476916759911615244007744068220",
    "1"
   ],
   [
    "5152714233547642924425453943362933487670747811842493222288515712293139545616",
    "17103096071991383067025581815771854616548148128756030455605263703527561205910",
    "1"
   ],
   [
    "15447374417074702151058563859314109816008078225151836487467438630855834394189",
    "17622407048249609790965427996195733485238662836944547380822418048735288467805",
    "1"
   ],
   [
    "8651195532923628086866919418072252415467334585437736376449571499823619232889",
    "14998264407811038481851626580083663176688649010417880010392153937322836336730",
    "1"
   ],
   [
    "7458457418733257481313115870700418691758253843381439582888002847293920934780",
    "542167882620985531753545552575713620470656945139339404822606611648495438637",
    "1"
   ],
   [
    "11654478610393315165602893189754745536674121231400580736622038568733670735982",
    "12257355783551921174011032474512008533083087974719806073358692398516604463769",
    "1"
   ],
   [
    "20322635549433112154444217943766297421929292916507426703075749847590584882286",
    "17064859567711980715716858605905961120685111304069430254040524583096277093911",
    "1"
   ],
   [
    "2351611421496751815297337091125626628153834298569012697092929966223240549367",
    "8981623119830487140187538030830803038148631170446603756509370347108713360372",
    "1"
   ],
   [
    "14536962684274075654744716239480828438557306956570655739004040615159003943312",
    "15989427664222476135240023865637576890575158594894752990204712714267063734873",
    "1"
   ],
   [
    "2619628326543741336308640920600617374518257676707551075589324630412339157610",
    "7966211775560790007913238855770952022525432549688555817284872825049075239614",
    "1"
   ],
   [
    "687223592036625422088204689474192126235881791115711355027369272731146690572",
    "5456875514001475540695452284699708537730275839107823178023311848531691970413",
    "1"
   ],
   [
    "6946456658180332721992546139783275797641755855230168345362036906695016641551",
    "11316286553478747631365490256288642487416717626257031294300167632075226007209",
    "1"
   ],
   [
    "18376919354566106859685708361984142415330243406938923421496478653958087728870",
    "8827642705442411540364786437220347440481285673685576911202226958271761055385",
    "1"
   ],
   [
    "6057728902703241688367200208848751084102678742749995632673910557958470618058",
    "7921304270410952949195938865544544978067811906564190603914442420062807566570",
    "1"
   ]
  ]
 }